import axios from 'axios';
import { nanoid } from 'nanoid';
import { makeCancelable } from '../store/utils';
// ######################################################################
//                              GAEL reducer
// ######################################################################
export const INIT_STATIC_CLASS = 'INIT_STATIC_CLASS';
export const INIT_BUILTINS = 'INIT_BUILTINS';
export const INIT_SKILLS = 'INIT_SKILLS';
export const INIT_MODELS = 'INIT_MODELS';

export const initializeStaticClass = (list) => ({ type: INIT_STATIC_CLASS, payload: { list } });
export const initializeBuiltins = (list) => ({ type: INIT_BUILTINS, payload: { list } });
export const initializeSkills = (list) => ({ type: INIT_SKILLS, payload: { list } });
export const initializeModels = (embeddings, defaultModel, workspace) => ({ type: INIT_MODELS, payload: { embeddings, defaultModel, workspace } });

// ######################################################################
//                              GAEL Corpus
// ######################################################################

export const asr = (workspaceName = 'default', payload = {}, language = null) => {
    return (dispatch, getState) => {
        return axios
            .post(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/asr${language == null ? '' : '?language=' + encodeURIComponent(language)}`, payload, {
                transformRequest: (d) => d,
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            })
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const get_corpus_list = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/wordscorpus`)
            .then(({ data }) => {
                return data.list;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const create_corpus = (workspaceName = 'default', payload = {}) => {
    if (!payload.corpus_name) {
        payload.corpus_name = payload.name;
    }
    payload.corpus_name = payload.corpus_name.trim();
    return (dispatch, getState) => {
        return axios
            .post(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/wordscorpus`, payload)
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const get_corpus = (workspaceName = 'default', corpusName) => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/wordscorpus/${encodeURIComponent(corpusName)}`)
            .then(({ data: { data } }) => {
                data.corpus_name = data.name;
                data.values?.forEach((element) => (element.id = nanoid()));
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const update_corpus = (workspaceName = 'default', corpusName, payload) => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/wordscorpus/${encodeURIComponent(corpusName)}`, payload)
            .then(({ data }) => {
                return payload.list;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const delete_corpus = (workspaceName = 'default', corpusName) => {
    return (dispatch, getState) => {
        return axios
            .delete(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/wordscorpus/${encodeURIComponent(corpusName)}`)
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

// ######################################################################
//                              GAEL Intents
// ######################################################################

export const get_intents_list = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents`)
            .then(({ data }) => {
                return data.intents;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const create_intent = (workspaceName = 'default', payload = {}) => {
    if (!payload.intent_name) {
        payload.intent_name = payload.name;
    }
    payload.intent_name = payload.intent_name.trim();
    payload.skill = 'neuralNetworkIntent';

    return (dispatch, getState) => {
        return axios
            .post(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents`, payload)
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const get_intent = (workspaceName = 'default', intentName) => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents/${encodeURIComponent(intentName)}`)
            .then(({ data: { intent } }) => {
                intent.name = intent.intentCodeName;
                intent.attributes?.forEach((element) => (element.id = nanoid()));
                return intent;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const update_intent = (workspaceName = 'default', intentName, payload) => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents/${encodeURIComponent(intentName)}`, payload)
            .then(({ data }) => {
                return data.utterances;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const delete_intent = (workspaceName = 'default', intentName) => {
    return (dispatch, getState) => {
        return axios
            .delete(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents/${encodeURIComponent(intentName)}`)
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const generate_utterances = (workspaceName = 'default', intentName, numberOfUtterances, sample1, sample2, sample3, autoValidate = true) => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents/${encodeURIComponent(intentName)}/generateUtterances`, {
                numberOfUtterances,
                sample1,
                sample2,
                sample3,
                autoValidate,
            })
            .then(({ data }) => {
                return data.utterances;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const update_utterances = (workspaceName = 'default', intentName, utterances, push = false, forceenable = false) => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents/${encodeURIComponent(intentName)}/utterances`, { push, utterances, forceenable })
            .then(({ data }) => {
                return data.utterances;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const update_utterance = (workspaceName = 'default', intentName, utterance, options = {}) => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents/${encodeURIComponent(intentName)}/utterance`, { ...options, utterance })
            .then(({ data }) => {
                return data.utterance;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const update_attributes = (workspaceName = 'default', intentName, attributes) => {
    // cleanup nanoid
    attributes = attributes.map((a) => ({ ...a, id: undefined }));
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents/${encodeURIComponent(intentName)}/attributes`, { attributes })
            .then(({ data }) => {
                return data.attributes;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const update_fallback = (workspaceName = 'default', intentName, payload) => {
    return (dispatch, getState) => {
        return makeCancelable(
            axios
                .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/intents/${encodeURIComponent(intentName)}/fallback`, payload)
                .then(({ data: { data } }) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                }),
        );
    };
};

// ######################################################################
//                              GEAL Class
// ######################################################################

export const get_class_list = (workspaceName = 'default', query) => {
    const config = {
        params: query ? query : {},
    };
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class`, config)
            .then(({ data: { classes } }) => {
                return classes;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const create_class = (workspaceName = 'default', payload = {}) => {
    if (!payload.class_name) {
        payload.class_name = payload.name;
    }
    payload.class_name = payload.class_name.trim();
    return (dispatch, getState) => {
        return axios
            .post(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class`, payload)
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const get_class = (workspaceName = 'default', className) => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(className)}`)
            .then(({ data }) => {
                return data.class;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const update_class = (workspaceName = 'default', className, payload) => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(className)}`, payload)
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const delete_class = (workspaceName = 'default', className) => {
    return (dispatch, getState) => {
        return axios
            .delete(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(className)}`)
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const export_class = (workspaceName = 'default', className, branch = 'draft') => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/export/class/${encodeURIComponent(className)}?branch=${encodeURIComponent(branch)}`)
            .then(({ data }) => {
                if (data.status == 'ok') return data.class;
                return null;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const import_class = (workspaceName = 'default', payload, option = null, class_name = null) => {
    return (dispatch, getState) => {
        return axios
            .put(
                `/api/1.0/gael/${encodeURIComponent(workspaceName)}/import/class${
                    option ? '?options=' + encodeURIComponent(option) + '&class_name=' + encodeURIComponent(class_name) : ''
                }`,
                payload,
                {
                    headers: {
                        'content-type': 'application/json',
                    },
                },
            )
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const update_class_fallback = (workspaceName = 'default', class_name, payload) => {
    return (dispatch, getState) => {
        return makeCancelable(
            axios
                .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(class_name)}/fallback`, payload)
                .then(({ data }) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                }),
        );
    };
};

export const get_class_annotations = (workspaceName = 'default', class_name) => {
    return (dispatch, getState) => {
        return makeCancelable(
            axios
                .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(class_name)}/annotations`)
                .then(({ data }) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                }),
        );
    };
};

// ######################################################################
//                              GEAL Configuration
// ######################################################################

export const get_skills = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/skills`)
            .then(({ data }) => {
                return data.skills;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const get_builtins = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/builtins`)
            .then(({ data }) => {
                return data.builtins;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const get_builtins_class = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        const { gael } = getState();
        if (gael.classes.initialized) {
            // return cached
            return Promise.resolve(gael.classes);
        }
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/builtins/class`)
            .then(({ data: { builtins } }) => {
                // update store (synch)
                builtins.forEach(({ intent_models }) => {
                    intent_models?.forEach(({ attributes }) => attributes?.forEach((a) => (a.id = nanoid())));
                });
                dispatch(initializeStaticClass(builtins));
                return gael.classes;
            })
            .catch((error) => {
                throw error;
            });
    };
};

// ######################################################################
//                              GEAL Train
// ######################################################################

const timeout = (ttl) => new Promise((resolve) => setTimeout(resolve, ttl));

export const update_train = (workspaceName, className) => {
    const config = {
        params: { async: true },
    };

    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(className)}/train`, null, config)
            .then(async ({ data: { status, task_id } }) => {
                // eslint-disable-next-line no-constant-condition
                while (true) {
                    // eslint-disable-next-line no-await-in-loop
                    await timeout(5000);
                    // eslint-disable-next-line no-await-in-loop
                    const result = await axios.get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/queue/${encodeURIComponent(task_id)}`);
                    if (result.data.status == 'completed') {
                        return;
                    }
                    if (result.data.status == 'error') {
                        throw new Error('Error');
                    }
                }
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const need_train = (workspaceName, className) => {
    return (dispatch, getState) => {
        return axios
            .get(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(className)}/needToTrain`)
            .then(({ data: { needToTrain } }) => {
                return needToTrain;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const publish_train = (workspaceName, className) => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(className)}/publish`)
            .then(({ data: { status } }) => {
                return status;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const test_utterance = (workspaceName, className, utterance) => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/gael/${encodeURIComponent(workspaceName)}/class/${encodeURIComponent(className)}/utterance`, { utterance })
            .then(({ data: { data } }) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const get_embeddings_models = () => {
    return (dispatch, getState) => {
        const { gael, app } = getState();
        const workspaceName = app.query.ws;
        if (!workspaceName || gael.models.workspace == workspaceName) {
            return makeCancelable(Promise.resolve());
        }
        return makeCancelable(
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/embeddings`)
                .then(({ data: { embeddings } }) => {
                    const data = {};
                    let defaultModel = null;
                    for (const e of embeddings) {
                        data[e.model ?? ''] = { ...e, value: e.model ?? '' };
                        if (e.default === false) {
                            defaultModel = e;
                        }
                    }
                    dispatch(initializeModels(data, defaultModel, workspaceName));
                    return data;
                })
                .catch((error) => {
                    return [{ label: 'No Embeddings', model: null, default: true }];
                    // throw error;
                }),
        );
    };
};
