// initial state.
const INITIAL_STATE = {
    skills: { initialized: false, label: 'Skills', options: [] },
    builtins: { initialized: false, label: 'Builtins', options: [] },
    classes: { initialized: false, label: 'Static Classes', options: [] },
    intents: {},
    models: {
        workspace: '',
        default: {
            label: 'No Embeddings',
            model: null,
            value: '',
            default: true,
        },
        embeddings: {
            '': {
                value: '',
                label: 'No Embeddings',
                model: null,
                default: true,
            },
        },
    },
};

import { INIT_STATIC_CLASS, INIT_BUILTINS, INIT_SKILLS, INIT_MODELS } from '../actions/gael';

const gael = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT_MODELS: {
            state.models = {
                default: action.payload.defaultModel ||  state.models.default,
                workspace: action.payload.workspace,
                embeddings: action.payload.embeddings,
            };
            return state;
        }
        case INIT_STATIC_CLASS: {
            const classes = action.payload.list || [];
            const intents = {};
            state.classes = { initialized: true, label: 'Static Classes', options: [] };
            classes
                .sort((elem1, elem2) => elem1.name.localeCompare(elem2.name))
                .forEach((c) => {
                    c.name = '$' + c.name;
                    c.readonly = true;
                    const intent = { label: c.name, options: [] };
                    intents[c.name] = intent;
                    c.intent_models.forEach((i) => {
                        i.intentCodeName = `${c.name}\u001D${i.name}`;
                        i.readonly = true;
                        i.static = true;
                        i.attributes?.forEach((a) => {
                            const name = a.corpus?.[0]?.corpusName;
                            if (name && name.startsWith('<')) {
                                a.corpus[0].corpusName = name.slice(1, -1);
                            }
                        });
                        intent.options.push({ value: i, type: 'intent', label: `${i.name}` });
                    });
                    state.classes.options.push({ value: c, type: 'class', label: `${c.name}` });
                });
            state.intents = intents;
            return state;
        }
        case INIT_BUILTINS: {
            const builtins = action.payload.list || [];
            state.builtins = { initialized: true, label: 'Builtins', options: [] };
            builtins
                .sort((elem1, elem2) => elem1.name.localeCompare(elem2.name))
                .forEach((builtin) => {
                    state.builtins.options.push({ value: builtin, type: 'builtin', label: `"${builtin.name}" - ${builtin.description}` });
                });
            return state;
        }
        case INIT_SKILLS: {
            const skills = action.payload.list || [];
            state.skills = { initialized: true, label: 'Skills', options: [] };
            skills
                .sort((elem1, elem2) => elem1.name.localeCompare(elem2.name))
                .forEach((skill) => {
                    state.skills.options.push({ value: skill, type: 'skill', label: `"${skill.name}" - ${skill.description}` });
                });
            return state;
        }
        default:
            return state;
    }
};

export default gael;
